.headerContainer {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0px 10px 0px 10px;
  z-index: 990;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 5px;

  .menuContainer {
    .navLinks1 {
      margin-right: 25px;
      font-size: 30px;
      text-decoration: none;
      font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      font-style: italic;
      color: #034f84;
      text-shadow: 1.25px 1.25px 0 #afafaf, 1.25px -1.25px 0 rgb(175, 175, 175),
        -1.25px 1.25px 0 rgb(175, 175, 175),
        -1.25px -1.25px 0 rgb(175, 175, 175), 1.25px 0px 0 rgb(175, 175, 175),
        0px 1.25px 0 rgb(175, 175, 175), -1.25px 0px 0 rgb(175, 175, 175),
        0px -1.25px 0 rgb(175, 175, 175);
      cursor: pointer;
    }

    .navLinks1:hover {
      font-size: 33px;
    }

    .active {
      text-decoration: none;
      font-weight: bold;
      // color: #57ca8f;
      color: white;
      text-shadow: 1.25px 1.25px 0 #034f84, 1.25px -1.25px 0 #034f84,
        -1.25px 1.25px 0 #034f84, -1.25px -1.25px 0 #034f84,
        1.25px 0px 0 #034f84, 0px 1.25px 0 #034f84, -1.25px 0px 0 #034f84,
        0px -1.25px 0 #034f84;
    }

    .navLinks2 {
      margin-right: 25px;
      font-size: 30px;
      text-decoration: none;
      font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      font-style: italic;
      color: #ca6357;
      text-shadow: 1.25px 1.25px 0 white, 1.25px -1.25px 0 white,
        -1.25px 1.25px 0 white, -1.25px -1.25px 0 white, 1.25px 0px 0 white,
        0px 1.25px 0 white, -1.25px 0px 0 white, 0px -1.25px 0 white;
      cursor: pointer;
    }

    .navLinks2:hover {
      font-size: 33px;
    }

    .active2 {
      text-decoration: none;
      font-weight: bold;
      color: white;
      text-shadow: 1.25px 1.25px 0 #ca6357, 1.25px -1.25px 0 #ca6357,
        -1.25px 1.25px 0 #ca6357, -1.25px -1.25px 0 #ca6357,
        1.25px 0px 0 #ca6357, 0px 1.25px 0 #ca6357, -1.25px 0px 0 #ca6357,
        0px -1.25px 0 #ca6357;
    }
  }

  .mobileMenuContainer {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .headerContainer {
    justify-content: center;
    padding: 0px;

    .menuContainer {
      display: none;
    }

    .mobileMenuContainer {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      .mobileMenuBtnContainer {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        width: 100%;

        .mobileMenuBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 35px;
          background-color: rgb(50, 50, 50);
          font-size: 24px;
          color: white;
          font-weight: bold;
          text-align: center;
          letter-spacing: 2px;
          text-decoration: none;
          border: none;
          outline: none;
          z-index: 999;
        }
      }

      .showMobileMenu {
        display: flex;
        position: absolute;
        justify-content: center;
        flex-direction: column;
        z-index: 997;
        width: 100%;
        top: 10px;
        transition: 0.75s;
        transform: translate(0, 25px);
      }

      .showMobileMenu ul {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        text-align: center;
        background-color: black;
        padding-bottom: 8px;
      }

      .showMobileMenu ul li {
        display: flex;
        position: relative;
        justify-content: center;
        width: 100%;
        margin-top: 10px;
      }

      .showMobileMenu ul li a {
        display: flex;
        justify-content: center;
        width: 100%;
        color: #4f8edb;
        font-family: sans-serif;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 2px;
        text-decoration: none;
        outline: none;
      }

      .hideMobileMenu {
        display: flex;
        position: absolute;
        justify-content: center;
        flex-direction: column;
        width: 100vw;
        z-index: 997;
        top: 32px;
        transform: translate(0, -200px);
        transition: 0.75s;
      }

      .hideMobileMenu ul {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        text-align: center;
        background-color: black;
        padding-bottom: 10px;
      }

      .hideMobileMenu ul li a {
        display: flex;
        justify-content: center;
        width: 100%;
        color: #4f8edb;
        font-family: sans-serif;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 2px;
        text-decoration: none;
        outline: none;
      }
    }
  }
}
