.skillsPageContainer {
  display: flex;
  position: relative;
  z-index: 500;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  // .separator {
  //   display: flex;
  //   position: relative;
  //   width: 90%;
  //   height: 5px;
  //   border-radius: 5px;
  //   background-color: black;
  //   margin: 30px 0px 30px 0px;
  // }

  .linkIconTitleContainer {
    display: flex;
    position: relative;
    width: 90%;
    justify-content: center;
    align-items: center;

    .linkIconTitle {
      font-size: 35px;
      letter-spacing: 2px;
      font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      text-align: center;
      color: #49db90;
      text-shadow: 1.25px 1.25px 0 rgb(100, 100, 100),
        1.25px -1.25px 0 rgb(100, 100, 100), -1.25px 1.25px 0 rgb(100, 100, 100),
        -1.25px -1.25px 0 rgb(100, 100, 100), 1.25px 0px 0 rgb(100, 100, 100),
        0px 1.25px 0 rgb(100, 100, 100), -1.25px 0px 0 rgb(100, 100, 100),
        0px -1.25px 0 rgb(100, 100, 100);
    }
  }

  .linkIconsContainer {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    justify-content: center;
    align-items: center;

    .techContainer {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 15px 20px 10px 20px;

      .techImage {
        width: 50px;
        height: 50px;
        margin-top: 20px;
      }
      .techName {
        font-size: 25px;
        letter-spacing: 1px;
        color: white;
        margin-top: 5px;
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .skillsPageContainer {
    .linkIconTitleContainer {
      .linkIconTitle {
        font-size: 30px;
        line-height: 40px;
      }
    }

    .linkIconsContainer {
      .techContainer {
        margin: 15px 15px 0px 15px;

        .techImage {
          width: 40px;
          height: 40px;
          margin-top: 20px;
        }
        .techName {
          font-size: 20px;
          margin-top: 5px;
        }
      }
    }
  }
}

@media only screen and (min-width: 414px) and (max-width: 599px) {
  .skillsPageContainer {
    .linkIconTitleContainer {
      width: 75%;
      margin-top: 25px;

      .linkIconTitle {
        font-size: 30px;
        line-height: 40px;
      }
    }

    .linkIconsContainer {
      margin-bottom: 25px;

      .techContainer {
        margin: 15px 15px 0px 15px;

        .techImage {
          width: 40px;
          height: 40px;
          margin-top: 20px;
        }
        .techName {
          font-size: 20px;
          margin-top: 5px;
        }
      }
    }
  }
}

@media only screen and (min-width: 360px) and (max-width: 413px) {
  .skillsPageContainer {
    .linkIconTitleContainer {
      width: 75%;
      margin-top: 25px;

      .linkIconTitle {
        font-size: 24px;
        line-height: 35px;
      }
    }

    .linkIconsContainer {
      margin-bottom: 25px;

      .techContainer {
        margin: 15px 15px 0px 15px;

        .techImage {
          width: 35px;
          height: 35px;
          margin-top: 20px;
        }
        .techName {
          font-size: 16px;
          margin-top: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 359px) {
  .skillsPageContainer {
    .linkIconTitleContainer {
      width: 75%;
      margin-top: 25px;

      .linkIconTitle {
        font-size: 24px;
        line-height: 35px;
      }
    }

    .linkIconsContainer {
      margin-bottom: 25px;

      .techContainer {
        margin: 15px 15px 0px 15px;

        .techImage {
          width: 35px;
          height: 35px;
          margin-top: 20px;
        }
        .techName {
          font-size: 16px;
          margin-top: 5px;
        }
      }
    }
  }
}
