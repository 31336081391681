.projectPageContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;

  .showImageModal {
    display: flex;
    position: fixed;
    z-index: 750;
    width: 100%;
    height: calc(100vh - 50px);
    top: 50px;
    background: rgba(50, 50, 50, 0.9);
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;

    .imageModalContainer {
      display: flex;
      position: fixed;
      width: 100%;
      justify-content: center;
      align-items: center;
      outline: none;
      cursor: pointer;

      .modalImage {
        width: 75%;
        border-radius: 10px;
      }
    }
  }

  .hideImageModal {
    display: none;
  }

  .projectImageContainer {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 15px 0px;

    .projectImage {
      display: flex;
      position: relative;
      width: 450px;
      border-radius: 5px;
      margin: 20px 25px;
      outline: none;
      cursor: pointer;
    }
  }

  .projectInfoMainContainer {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;

    .projectInfoContainer1 {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      margin: 0px 15px;

      .projectNameDatesContainer {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 25px;

        .projectName {
          margin-right: 30px;
          font-size: 50px;
          letter-spacing: 1px;
          text-decoration: none;
          color: #57ca8f;
          text-shadow: 1.25px 1.25px 0 rgb(100, 100, 100),
            1.25px -1.25px 0 rgb(100, 100, 100),
            -1.25px 1.25px 0 rgb(100, 100, 100),
            -1.25px -1.25px 0 rgb(100, 100, 100),
            1.25px 0px 0 rgb(100, 100, 100), 0px 1.25px 0 rgb(100, 100, 100),
            -1.25px 0px 0 rgb(100, 100, 100), 0px -1.25px 0 rgb(100, 100, 100);
        }

        .projectDates {
          font-size: 35px;
          letter-spacing: 1px;
          color: #fefbd8;
        }
      }

      .projectTechContainer {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        .techContainer {
          display: flex;
          position: relative;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 10px 10px;

          .techImage {
            width: 50px;
            height: 50px;
          }
          .techName {
            font-size: 25px;
            color: white;
            margin-top: 5px;
          }
        }
      }
    }

    .projectInfoContainer2 {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 50%;
      margin: 0px 15px;

      .projectLinksContainer {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 25px;

        a {
          font-size: 30px;
          letter-spacing: 1px;
          text-decoration: none;
          color: #57ca8f;
          text-shadow: 1.25px 1.25px 0 rgb(100, 100, 100),
            1.25px -1.25px 0 rgb(100, 100, 100),
            -1.25px 1.25px 0 rgb(100, 100, 100),
            -1.25px -1.25px 0 rgb(100, 100, 100),
            1.25px 0px 0 rgb(100, 100, 100), 0px 1.25px 0 rgb(100, 100, 100),
            -1.25px 0px 0 rgb(100, 100, 100), 0px -1.25px 0 rgb(100, 100, 100);
        }
      }

      .projectSummaryContainer {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 100%;

        .projectSummary {
          font-size: 25px;
          letter-spacing: 1px;
          line-height: 35px;
          color: white;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .projectPageContainer {
    .showImageModal {
      .imageModalContainer {
        .modalImage {
          width: 90%;
        }
      }
    }

    .projectImageContainer {
      justify-content: center;
      flex-wrap: wrap;

      .projectImage {
        width: 315px;
        margin: 0px 10px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .projectPageContainer {
    .showImageModal {
      .imageModalContainer {
        .modalImage {
          width: 90%;
        }
      }
    }

    .projectImageContainer {
      justify-content: center;
      flex-wrap: wrap;

      .projectImage {
        width: 325px;
        margin: 20px 25px;
      }
    }

    .projectInfoMainContainer {
      .projectInfoContainer1 {
        margin: 0px 15px;

        .projectNameDatesContainer {
          margin-bottom: 25px;

          .projectName {
            margin-right: 30px;
            font-size: 40px;
          }
        }

        .projectTechContainer {
          .techContainer {
            margin: 10px 10px;

            .techImage {
              width: 40px;
              height: 40px;
            }

            .techName {
              font-size: 20px;
              margin-top: 5px;
            }
          }
        }
      }

      .projectInfoContainer2 {
        margin: 0px 15px;

        .projectLinksContainer {
          margin-bottom: 25px;

          a {
            font-size: 30px;
          }
        }

        .projectSummaryContainer {
          .projectSummary {
            font-size: 25px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .projectPageContainer {
    .showImageModal {
      height: calc(100vh - 35px);
      top: 35px;

      .imageModalContainer {
        .modalImage {
          width: 90%;
        }
      }
    }

    .projectImageContainer {
      flex-direction: column;
      justify-content: center;

      .projectImage {
        width: 500px;
        margin: 15px 0px 15px 0px;
      }
    }

    .projectInfoMainContainer {
      flex-direction: column;
      justify-content: center;

      .projectInfoContainer1 {
        width: 90%;
        margin: 0px;

        .projectNameDatesContainer {
          margin-bottom: 25px;

          .projectName {
            font-size: 35px;
            margin-right: 0px;
          }
        }

        .projectTechContainer {
          margin-bottom: 25px;

          .techContainer {
            .techImage {
              width: 40px;
              height: 40px;
            }

            .techName {
              font-size: 20px;
            }
          }
        }
      }

      .projectInfoContainer2 {
        width: 90%;
        margin: 0px 0px 15px 0px;

        .projectLinksContainer {
          margin-bottom: 25px;

          a {
            font-size: 30px;
          }
        }

        .projectSummaryContainer {
          .projectSummary {
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 414px) and (max-width: 599px) {
  .projectPageContainer {
    .showImageModal {
      height: calc(100vh - 35px);
      top: 35px;

      .imageModalContainer {
        .modalImage {
          width: 90%;
        }
      }
    }

    .projectImageContainer {
      flex-direction: column;
      justify-content: center;

      .projectImage {
        width: 360px;
        margin: 15px 0px 15px 0px;
      }
    }

    .projectInfoMainContainer {
      flex-direction: column;
      justify-content: center;

      .projectInfoContainer1 {
        width: 90%;
        margin: 0px;

        .projectNameDatesContainer {
          margin-bottom: 15px;

          .projectName {
            font-size: 35px;
            margin-right: 0px;
          }
        }

        .projectTechContainer {
          margin-bottom: 15px;

          .techContainer {
            .techImage {
              width: 40px;
              height: 40px;
            }

            .techName {
              font-size: 20px;
            }
          }
        }
      }

      .projectInfoContainer2 {
        width: 90%;
        margin: 0px 0px 15px 0px;

        .projectLinksContainer {
          margin-bottom: 15px;

          a {
            font-size: 30px;
          }
        }

        .projectSummaryContainer {
          .projectSummary {
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 360px) and (max-width: 413px) {
  .projectPageContainer {
    .showImageModal {
      height: calc(100vh - 35px);
      top: 35px;

      .imageModalContainer {
        .modalImage {
          width: 90%;
        }
      }
    }

    .projectImageContainer {
      flex-direction: column;
      justify-content: center;

      .projectImage {
        width: 300px;
        margin: 15px 0px 15px 0px;
      }
    }

    .projectInfoMainContainer {
      flex-direction: column;
      justify-content: center;
      margin-bottom: 10px;

      .projectInfoContainer1 {
        width: 90%;
        margin: 0px;

        .projectNameDatesContainer {
          margin-bottom: 15px;

          .projectName {
            font-size: 30px;
            margin-right: 0px;
          }
        }

        .projectTechContainer {
          margin-bottom: 15px;

          .techContainer {
            .techImage {
              width: 35px;
              height: 35px;
            }

            .techName {
              font-size: 16px;
            }
          }
        }
      }

      .projectInfoContainer2 {
        width: 90%;
        margin: 0px 0px 15px 0px;

        .projectLinksContainer {
          margin-bottom: 15px;

          a {
            font-size: 24px;
          }
        }

        .projectSummaryContainer {
          .projectSummary {
            font-size: 18px;
            line-height: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 359px) {
  .projectPageContainer {
    .showImageModal {
      height: calc(100vh - 35px);
      top: 35px;

      .imageModalContainer {
        .modalImage {
          width: 90%;
        }
      }
    }

    .projectImageContainer {
      flex-direction: column;
      justify-content: center;

      .projectImage {
        width: 275px;
        margin: 1px 0px 10px 0px;
      }
    }

    .projectInfoMainContainer {
      flex-direction: column;
      justify-content: center;

      .projectInfoContainer1 {
        width: 90%;
        margin: 0px;

        .projectNameDatesContainer {
          margin-bottom: 15px;

          .projectName {
            font-size: 30px;
            margin-right: 0px;
          }
        }

        .projectTechContainer {
          margin-bottom: 15px;

          .techContainer {
            .techImage {
              width: 35px;
              height: 35px;
            }

            .techName {
              font-size: 16px;
            }
          }
        }
      }

      .projectInfoContainer2 {
        width: 90%;
        margin: 0px 0px 15px 0px;

        .projectLinksContainer {
          margin-bottom: 15px;

          a {
            font-size: 24px;
          }
        }

        .projectSummaryContainer {
          .projectSummary {
            font-size: 18px;
            line-height: 30px;
          }
        }
      }
    }
  }
}
