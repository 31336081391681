.contactPageContainer {
  display: flex;
  position: relative;
  z-index: 500;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;

  .showEmailMsg {
    display: flex;
    position: fixed;
    z-index: 750;
    width: 100%;
    height: calc(100vh - 50px);
    top: 50px;
    background: rgba(50, 50, 50, 0.9);
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: sans-serif;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 2px;
    text-decoration: none;
    color: rgb(0, 200, 0);
    cursor: pointer;
  }

  .hideEmailMsg {
    display: none;
  }

  .showEmailErr {
    display: flex;
    position: fixed;
    z-index: 750;
    width: 100%;
    height: calc(100vh - 50px);
    top: 50px;
    background: rgba(50, 50, 50, 0.9);
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: sans-serif;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    text-decoration: none;
    color: orange;
    cursor: pointer;
  }

  .hideEmailErr {
    display: none;
  }

  .showEmailWarn {
    display: flex;
    position: fixed;
    z-index: 750;
    width: 100%;
    height: calc(100vh - 50px);
    top: 50px;
    background: rgba(50, 50, 50, 0.9);
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: sans-serif;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    text-decoration: none;
    color: rgb(225, 0, 0);
    cursor: pointer;
  }

  .hideEmailWarn {
    display: none;
  }

  .contactInfoContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .contactLinkContainer {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 40px 0px 40px 0px;
      cursor: pointer;

      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        outline: none;
        text-decoration: none;
        color: black;

        .linkIcon {
          width: 50px;
          height: 50px;
          margin-bottom: 5px;
        }

        .linkName {
          font-size: 25px;
          color: white;
        }

        .linkUrl {
          font-size: 20px;
          font-style: italic;
          color: white;
        }
      }
    }
  }

  .separator {
    display: flex;
    position: relative;
    width: 5px;
    height: 90%;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
    margin: 30px 0px 30px 0px;
  }

  .contactFormContainer {
    display: flex;
    flex-direction: column;

    .contactInputContainer1 {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .contactTitle1 {
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        font-style: italic;
        font-size: 25px;
        text-align: left;
        letter-spacing: 1px;
        color: #fefbd8;
        text-shadow: 0 0 15px black;
      }

      .contactInput1 {
        cursor: text;
        outline: none;
        display: flex;
        position: relative;
        width: 500px;
        margin: 10px 0px 20px 10px;
        border-style: inset;
        border-radius: 8px;
        border-width: 2px;
        border-color: initial;
        padding: 5px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 18px;
        text-align: center;
        color: black;
      }
    }

    .contactInputContainer2 {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .contactTitle2 {
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        font-style: italic;
        font-size: 25px;
        text-align: left;
        letter-spacing: 1px;
        color: #fefbd8;
        text-shadow: 0 0 15px black;
      }

      .contactInput2 {
        cursor: text;
        outline: none;
        display: flex;
        position: relative;
        width: 500px;
        height: 250px;
        margin: 10px 0px 20px 10px;
        border-style: inset;
        border-color: initial;
        border-width: 2px;
        border-radius: 8px;
        padding: 5px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 18px;
        text-align: left;
        color: black;
        resize: none;
      }
    }

    .contactSubmitBtn {
      font-size: 30px;
      text-decoration: none;
      font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      font-style: italic;
      color: #ca6357;
      text-shadow: 0 0 15px black;
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
    }

    .contactSubmitBtn:hover {
      font-size: 33px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contactPageContainer {
    flex-direction: column-reverse;
    justify-content: center;

    .showEmailMsg {
      width: 100%;
      font-size: 30px;
      line-height: 40px;
    }

    .showEmailErr {
      width: 100%;
      font-size: 24px;
      line-height: 36px;
    }

    .showEmailWarn {
      width: 100%;
      font-size: 24px;
      line-height: 36px;
    }

    .contactInfoContainer {
      flex-direction: row;
      justify-content: space-evenly;

      .contactLinkContainer {
        flex-direction: row;
        justify-content: space-evenly;
        margin: 0px 30px 0px 30px;

        a {
          display: flex;
          flex-direction: column;

          .linkName {
            font-size: 20px;
            margin-bottom: 5px;
          }

          .linkUrl {
            font-size: 20px;
          }
        }
      }
    }

    .separator {
      width: 90%;
      height: 5px;
      margin: 40px 0px;
    }

    .contactFormContainer {
      width: 90%;
      margin: 0px 0px 20px 0px;

      .contactInputContainer1 {
        .contactTitle1 {
          font-size: 20px;
        }

        .contactInput1 {
          width: 100%;
          margin: 10px 0px 25px 0px;
          font-size: 20px;
        }
      }

      .contactInputContainer2 {
        .contactTitle2 {
          font-size: 20px;
        }

        .contactInput2 {
          width: 100%;
          height: 250px;
          margin: 10px 0px 25px 0px;
          font-size: 20px;
        }
      }

      .contactSubmitBtn {
        font-size: 30px;
      }

      .contactSubmitBtn:hover {
        font-size: 28px;
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .contactPageContainer {
    flex-direction: column-reverse;
    justify-content: center;

    .showEmailMsg {
      width: 100%;
      height: calc(100vh - 35px);
      top: 35px;
      font-size: 30px;
      line-height: 40px;
    }

    .showEmailErr {
      width: 100%;
      height: calc(100vh - 35px);
      top: 35px;
      font-size: 24px;
      line-height: 36px;
    }

    .showEmailWarn {
      width: 100%;
      height: calc(100vh - 35px);
      top: 35px;
      font-size: 24px;
      line-height: 36px;
    }

    .contactInfoContainer {
      flex-direction: row;
      justify-content: space-evenly;

      .contactLinkContainer {
        flex-direction: row;
        justify-content: space-evenly;
        margin: 0px 30px 0px 30px;

        a {
          display: flex;
          flex-direction: column;

          .linkIcon {
            width: 40px;
            height: 40px;
            margin-bottom: 5px;
          }

          .linkName {
            font-size: 20px;
            margin-bottom: 5px;
          }

          .linkUrl {
            font-size: 20px;
          }
        }
      }
    }

    .separator {
      width: 90%;
      height: 5px;
      margin: 40px 0px;
    }

    .contactFormContainer {
      width: 90%;
      margin: 0px 0px 20px 0px;

      .contactInputContainer1 {
        .contactTitle1 {
          font-size: 20px;
        }

        .contactInput1 {
          width: 100%;
          margin: 10px 0px 15px 0px;
          font-size: 20px;
        }
      }

      .contactInputContainer2 {
        .contactTitle2 {
          font-size: 20px;
        }

        .contactInput2 {
          width: 100%;
          height: 250px;
          margin: 10px 0px 15px 0px;
          font-size: 20px;
        }
      }

      .contactSubmitBtn {
        font-size: 30px;
      }

      .contactSubmitBtn:hover {
        font-size: 28px;
      }
    }
  }
}

@media only screen and (min-width: 414px) and (max-width: 599px) {
  .contactPageContainer {
    flex-direction: column-reverse;
    height: auto;
    justify-content: center;

    .showEmailMsg {
      width: 100%;
      height: calc(100vh - 35px);
      top: 35px;
      font-size: 24px;
      line-height: 36px;
    }

    .showEmailErr {
      width: 100%;
      height: calc(100vh - 35px);
      top: 35px;
      font-size: 24px;
      line-height: 36px;
    }

    .showEmailWarn {
      width: 100%;
      height: calc(100vh - 35px);
      top: 35px;
      font-size: 24px;
      line-height: 36px;
    }

    .contactInfoContainer {
      .contactLinkContainer {
        margin: 25px 0px 15px 0px;

        a {
          display: flex;
          flex-direction: column;

          .linkIcon {
            width: 45px;
            height: 45px;
          }

          .linkName {
            font-size: 20px;
            margin-bottom: 5px;
          }

          .linkUrl {
            font-size: 20px;
          }
        }
      }
    }

    .separator {
      width: 90%;
      height: 5px;
      margin: 15px 0px 15px 0px;
    }

    .contactFormContainer {
      width: 90%;
      margin: 15px 0px 25px 0px;

      .contactInputContainer1 {
        .contactTitle1 {
          font-size: 20px;
        }

        .contactInput1 {
          width: 100%;
          margin: 10px 0px 15px 0px;
          font-size: 20px;
        }
      }

      .contactInputContainer2 {
        .contactTitle2 {
          font-size: 20px;
        }

        .contactInput2 {
          width: 100%;
          height: 250px;
          margin: 10px 0px 15px 0px;
          font-size: 20px;
        }
      }

      .contactSubmitBtn {
        font-size: 30px;
      }

      .contactSubmitBtn:hover {
        font-size: 28px;
      }
    }
  }
}

@media only screen and (min-width: 360px) and (max-width: 413px) {
  .contactPageContainer {
    flex-direction: column-reverse;
    height: auto;
    justify-content: center;

    .showEmailMsg {
      width: 100%;
      height: calc(100vh - 35px);
      top: 35px;
      font-size: 18px;
      line-height: 30px;
    }

    .showEmailErr {
      width: 100%;
      height: calc(100vh - 35px);
      top: 35px;
      font-size: 18px;
      line-height: 30px;
    }

    .showEmailWarn {
      width: 100%;
      height: calc(100vh - 35px);
      top: 35px;
      font-size: 18px;
      line-height: 30px;
    }

    .contactInfoContainer {
      .contactLinkContainer {
        margin: 15px 0px 15px 0px;

        a {
          display: flex;
          flex-direction: column;

          .linkIcon {
            width: 40px;
            height: 40px;
          }

          .linkName {
            font-size: 20px;
            margin-bottom: 5px;
          }

          .linkUrl {
            font-size: 20px;
          }
        }
      }
    }

    .separator {
      width: 90%;
      height: 5px;
      margin: 15px 0px 15px 0px;
    }

    .contactFormContainer {
      width: 90%;
      margin: 20px 0px;

      .contactInputContainer1 {
        .contactTitle1 {
          font-size: 18px;
        }

        .contactInput1 {
          width: 100%;
          margin: 10px 0px 15px 0px;
          font-size: 18px;
        }
      }

      .contactInputContainer2 {
        .contactTitle2 {
          font-size: 18px;
        }

        .contactInput2 {
          width: 100%;
          height: 200px;
          margin: 10px 0px 15px 0px;
          font-size: 18px;
        }
      }

      .contactSubmitBtn {
        font-size: 24px;
      }

      .contactSubmitBtn:hover {
        font-size: 28px;
      }
    }
  }
}

@media only screen and (max-width: 359px) {
  .contactPageContainer {
    flex-direction: column-reverse;
    height: auto;
    justify-content: center;

    .showEmailMsg {
      width: 100%;
      height: calc(100vh - 35px);
      top: 35px;
      font-size: 18px;
      line-height: 30px;
    }

    .showEmailErr {
      width: 100%;
      height: calc(100vh - 35px);
      top: 35px;
      font-size: 18px;
      line-height: 30px;
    }

    .showEmailWarn {
      width: 100%;
      height: calc(100vh - 35px);
      top: 35px;
      font-size: 18px;
      line-height: 30px;
    }

    .contactInfoContainer {
      .contactLinkContainer {
        margin: 25px 0px 15px 0px;

        a {
          display: flex;
          flex-direction: column;

          .linkIcon {
            width: 40px;
            height: 40px;
          }

          .linkName {
            font-size: 20px;
            margin-bottom: 5px;
          }

          .linkUrl {
            font-size: 20px;
          }
        }
      }
    }

    .separator {
      width: 90%;
      height: 5px;
      margin: 15px 0px 15px 0px;
    }

    .contactFormContainer {
      width: 90%;
      margin: 15px 0px 25px 0px;

      .contactInputContainer1 {
        .contactTitle1 {
          font-size: 18px;
        }

        .contactInput1 {
          width: 100%;
          margin: 10px 0px 15px 0px;
          font-size: 18px;
        }
      }

      .contactInputContainer2 {
        .contactTitle2 {
          font-size: 18px;
        }

        .contactInput2 {
          width: 100%;
          height: 200px;
          margin: 10px 0px 15px 0px;
          font-size: 18px;
        }
      }

      .contactSubmitBtn {
        font-size: 24px;
      }

      .contactSubmitBtn:hover {
        font-size: 28px;
      }
    }
  }
}
