.workPageContainer {
  display: flex;
  position: relative;
  z-index: 500;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;

  .projectNavLink {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: rgb(68, 68, 68);
    box-shadow: 1.25px 1.25px 0 rgba(100, 100, 100, 0.25),
      1.25px -1.25px 0 rgba(100, 100, 100, 0.25),
      -1.25px 1.25px 0 rgba(100, 100, 100, 0.25),
      -1.25px -1.25px 0 rgba(100, 100, 100, 0.25),
      1.25px 0px 0 rgba(100, 100, 100, 0.25),
      0px 1.25px 0 rgba(100, 100, 100, 0.25),
      -1.25px 0px 0 rgba(100, 100, 100, 0.25),
      0px -1.25px 0 rgba(100, 100, 100, 0.25);
    border-radius: 10px;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    margin: 50px;

    .projectContainer {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 10px;

      .projectImageContainer {
        margin-bottom: 10px;

        .projectImage {
          width: 600px;
          border-radius: 5px;
        }
      }

      .projectTitleContainer {
        width: 90%;

        .projectName1 {
          color: white;
          font-size: 40px;
          letter-spacing: 1px;
          text-decoration: none;
          text-align: center;
        }

        .projectName2 {
          color: white;
          font-size: 40px;
          letter-spacing: 1px;
          text-decoration: none;
          text-align: center;
          margin-top: 5px;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .workPageContainer {
    justify-content: space-evenly;

    .projectNavLink {
      margin: 35px;

      .projectContainer {
        .projectImageContainer {
          .projectImage {
            width: 360px;
          }
        }

        .projectTitleContainer {
          .projectName1 {
            font-size: 30px;
          }

          .projectName2 {
            font-size: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .workPageContainer {
    justify-content: space-evenly;

    .projectNavLink {
      margin: 25px;

      .projectContainer {
        .projectImageContainer {
          .projectImage {
            width: 300px;
          }
        }

        .projectTitleContainer {
          .projectName1 {
            font-size: 24px;
          }

          .projectName2 {
            font-size: 24px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .workPageContainer {
    padding: 25px 0px 0px 0px;

    .projectNavLink {
      margin: 0px 0px 25px 0px;

      .projectContainer {
        .projectImageContainer {
          .projectImage {
            width: 400px;
          }
        }

        .projectTitleContainer {
          .projectName1 {
            font-size: 30px;
          }

          .projectName2 {
            font-size: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 414px) and (max-width: 599px) {
  .workPageContainer {
    padding: 25px 0px 0px 0px;

    .projectNavLink {
      margin: 0px 0px 25px 0px;

      .projectContainer {
        .projectImageContainer {
          .projectImage {
            width: 360px;
          }
        }

        .projectTitleContainer {
          .projectName1 {
            font-size: 30px;
          }

          .projectName2 {
            font-size: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 360px) and (max-width: 413px) {
  .workPageContainer {
    padding: 25px 0px 0px 0px;

    .projectNavLink {
      margin: 0px 0px 25px 0px;

      .projectContainer {
        .projectImageContainer {
          .projectImage {
            width: 300px;
          }
        }

        .projectTitleContainer {
          .projectName1 {
            font-size: 24px;
          }

          .projectName2 {
            font-size: 24px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 359px) {
  .workPageContainer {
    padding: 25px 0px 0px 0px;

    .projectNavLink {
      margin: 0px 0px 25px 0px;

      .projectContainer {
        .projectImageContainer {
          .projectImage {
            width: 275px;
          }
        }

        .projectTitleContainer {
          .projectName1 {
            font-size: 24px;
          }

          .projectName2 {
            font-size: 24px;
          }
        }
      }
    }
  }
}
