.aboutPageContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  .aboutMainContainer {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 25px;

    .aboutNameImageContainer {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .aboutNameContainer {
        display: flex;
        position: relative;

        .aboutName {
          font-family: sans-serif;
          font-size: 36px;
          letter-spacing: 1px;
          color: #fefbd8;
        }
      }

      .aboutImageContainer {
        display: flex;
        position: relative;
        margin: 20px 0px 20px 0px;

        .socialHeadshot {
          display: flex;
          position: relative;
          width: 350px;
          border-radius: 10px;
        }
      }

      .aboutTitleContainer {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .aboutTitle {
          font-family: sans-serif;
          font-size: 32px;
          letter-spacing: 1px;
          line-height: 36px;
          color: #fefbd8;
        }
      }
    }

    .aboutBioContainer {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 65%;
      justify-content: center;
      align-items: center;
      margin: 0px 0px 0px 50px;

      .personalBio1 {
        font-family: sans-serif;
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 34px;
        color: #ffffff;
        margin-bottom: 20px;
      }
      .personalBio2 {
        font-family: sans-serif;
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 34px;
        color: #ffffff;
        margin-bottom: 20px;
      }
      .personalBio3 {
        font-family: sans-serif;
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 34px;
        color: #ffffff;
        margin-bottom: 20px;
      }
      .personalBio4 {
        font-family: sans-serif;
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 34px;
        color: #ffffff;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .aboutPageContainer {
    .aboutMainContainer {
      flex-direction: row;
      margin: 25px;

      .aboutNameImageContainer {
        .aboutNameContainer {
          .aboutName {
            font-size: 36px;
          }
        }

        .aboutImageContainer {
          margin: 20px 0px 20px 0px;

          .socialHeadshot {
            width: 325px;
            border-radius: 10px;
          }
        }

        .aboutTitleContainer {
          .aboutTitle {
            font-size: 32px;
          }
        }
      }

      .aboutBioContainer {
        width: 60%;
        margin: 0px 0px 0px 50px;

        .personalBio1 {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 20px;
        }
        .personalBio2 {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 20px;
        }
        .personalBio3 {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 20px;
        }
        .personalBio4 {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .aboutPageContainer {
    .aboutMainContainer {
      flex-direction: column;
      margin: 25px;

      .aboutNameImageContainer {
        .aboutNameContainer {
          .aboutName {
            font-size: 34px;
          }
        }

        .aboutImageContainer {
          margin: 20px 0px 20px 0px;

          .socialHeadshot {
            width: 300px;
            border-radius: 10px;
          }
        }

        .aboutTitleContainer {
          flex-direction: row;

          .aboutTitle {
            font-size: 30px;
            line-height: 34px;
            margin: 0px 5px 0px 5px;
          }
        }
      }

      .aboutBioContainer {
        width: 100%;
        margin: 30px 0px 0px 0px;

        .personalBio1 {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 20px;
        }
        .personalBio2 {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 20px;
        }
        .personalBio3 {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 20px;
        }
        .personalBio4 {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .aboutPageContainer {
    .aboutMainContainer {
      flex-direction: column;
      margin: 25px;

      .aboutNameImageContainer {
        .aboutNameContainer {
          .aboutName {
            font-size: 30px;
          }
        }

        .aboutImageContainer {
          margin: 20px 0px 20px 0px;

          .socialHeadshot {
            width: 250px;
            border-radius: 10px;
          }
        }

        .aboutTitleContainer {
          flex-direction: row;

          .aboutTitle {
            font-size: 24px;
            line-height: 30px;
            margin: 0px 5px 0px 5px;
          }
        }
      }

      .aboutBioContainer {
        width: 100%;
        margin: 30px 0px 0px 0px;

        .personalBio1 {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 20px;
        }
        .personalBio2 {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 20px;
        }
        .personalBio3 {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 20px;
        }
        .personalBio4 {
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
  }
}

@media only screen and (min-width: 414px) and (max-width: 599px) {
  .aboutPageContainer {
    .aboutMainContainer {
      flex-direction: column;
      margin: 25px;

      .aboutNameImageContainer {
        .aboutNameContainer {
          .aboutName {
            font-size: 24px;
          }
        }

        .aboutImageContainer {
          margin: 15px 0px 15px 0px;

          .socialHeadshot {
            width: 200px;
            border-radius: 10px;
          }
        }

        .aboutTitleContainer {
          .aboutTitle {
            font-size: 20px;
            line-height: 26px;
            margin: 0px 0px 0px 0px;
          }
        }
      }

      .aboutBioContainer {
        width: 100%;
        margin: 20px 0px 0px 0px;

        .personalBio1 {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 15px;
        }
        .personalBio2 {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 15px;
        }
        .personalBio3 {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 15px;
        }
        .personalBio4 {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
}

@media only screen and (min-width: 360px) and (max-width: 413px) {
  .aboutPageContainer {
    .aboutMainContainer {
      flex-direction: column;
      margin: 25px;

      .aboutNameImageContainer {
        .aboutNameContainer {
          .aboutName {
            font-size: 20px;
          }
        }

        .aboutImageContainer {
          margin: 15px 0px 15px 0px;

          .socialHeadshot {
            width: 150px;
            border-radius: 10px;
          }
        }

        .aboutTitleContainer {
          .aboutTitle {
            font-size: 18px;
            line-height: 24px;
            margin: 0px 0px 0px 0px;
          }
        }
      }

      .aboutBioContainer {
        width: 100%;
        margin: 20px 0px 0px 0px;

        .personalBio1 {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 15px;
        }
        .personalBio2 {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 15px;
        }
        .personalBio3 {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 15px;
        }
        .personalBio4 {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
}

@media only screen and (max-width: 359px) {
  .aboutPageContainer {
    .aboutMainContainer {
      flex-direction: column;
      margin: 25px;

      .aboutNameImageContainer {
        .aboutNameContainer {
          .aboutName {
            font-size: 20px;
          }
        }

        .aboutImageContainer {
          margin: 15px 0px 15px 0px;

          .socialHeadshot {
            width: 125px;
            border-radius: 10px;
          }
        }

        .aboutTitleContainer {
          .aboutTitle {
            font-size: 18px;
            line-height: 24px;
            margin: 0px 0px 0px 0px;
          }
        }
      }

      .aboutBioContainer {
        width: 100%;
        margin: 20px 0px 0px 0px;

        .personalBio1 {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 15px;
        }
        .personalBio2 {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 15px;
        }
        .personalBio3 {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 15px;
        }
        .personalBio4 {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
}
